<template>
  <section :class="['container', {'ocultar-overflow-y': siMuestraRutaHija}]">
    <article class="pt-3">
      <header class="h5-responsive text-center text-lg-left mb-3">
        Filtros de búsqueda
      </header>
      <FiltrosTipo1
        :zonas="zonasFiltro"
        :tipos-cli="tiposFiltro"
        :conDePago="condicionesPagoSelect"
        :tipPrecio="tipoPrecio"
        :ven="vendedoresFiltro"
        facturas
        nombre
        fecha
        @zonasAFiltrar="aplicarFiltrosZonas($event.estados, $event.municipios, $event.parroquias)"
        @tiposClienteAFiltrar="tiposAFiltrar=$event; aplicarFiltros()"
        @vendedorAFiltrar="vendedorAFiltrar=$event; aplicarFiltros(); sucCam = []"
        @nombreAFiltrar="nombreAFiltrar=$event; aplicarFiltros()"
        @condiPagoAFiltrar="condiPagoAFiltrar=$event; aplicarFiltros()"
        @tipoPrecioAFiltrar="tipoPrecioFiltrar=$event; aplicarFiltros()"
        @estatusFacturaAFiltrar="estatusFacturaAFiltrar=$event; aplicarFiltros()"
        @fechaAFiltrar="fechaAFiltrar=$event; aplicarFiltros()"
        @eliminarFiltros="eliminarFiltrosSeleccionados"
      />
      <div
        v-if="cargando"
        class="mensaje-no-items py-3"
      >
        <LoaderKel />
        <p class="texto mt-2">
          Cargando
        </p>
      </div>
      <ul
        v-else-if="Object.keys(clientes).length"
        class="table t-clientes"
      >
        <li class="encabezado sticky">
          <div class="row align-items-center mx-0">
            <div class="col-12 col-md px-0 text-center text-sm-left">
              <header class="h4-responsive">Clientes</header>
              <p class="descripcion text-capitalize">En lista: {{Object.keys(clientes).length}} empresas, {{totalSucursales}} sucursales</p>
            </div>
            <div
              v-if="vendedorAFiltrar.length == 1"
              class="col-12 col-md-auto"
            >
            </div>
          </div>
          <div class="thead claro">
            <div class="col-12 col-md-2">Empresa</div>
            <div class="col-12 col-md-2">Tipos</div>
            <div class="col-12 col-md-2">
              Estatus de factura
              <span class="small-block">
                Días
              </span>
            </div>
            <div class="col-12 col-md-2">Zonas</div>
            <div class="col-12 col-md-2">Condición de pago</div>
            <div class="col-12 col-md-2">Tipo de precio</div>
          </div>
        </li>
        <router-link
          :to="{name: 'Cliente', params: {id: empresa.id_emp}}"
          custom
          v-slot="{navigate}"
          v-for="empresa in clientes"
          :key="empresa.id_emp"
          class="contenido cursor-pointer"
        >
          <li @click="navigate">
            <div class="col col-md-2" data-columna="Empresa">
              <p class="text-capitalize">
                {{ empresa.nom_emp }}
              </p>
            </div>
            <div class="col col-md-2" data-columna="Tipos">
              <p :class="['text-break', {'text-muted font-italic': listarDatosEmpresa(empresa.tipos, 'tipos empresa') === 'Sin tipo especificado'}]">
                {{ listarDatosEmpresa(empresa.tipos, 'tipos empresa') }}
              </p>
            </div>
            <div class="col col-md-2" data-columna="Condición de factura">
              <p :class="[asignarColorEstado(empresa.est_fac), 'text-capitalize font-weight-bold']">
                {{empresa.est_fac}}
                <small v-if="empresa.fec_ven_fac" :class="[diasEstatusFac(empresa.fec_ven_fac, empresa.fec_pag_fac).color, 'font-weight-bold d-block']"
                >
                  {{diasEstatusFac(empresa.fec_ven_fac, empresa.fec_pag_fac).dias}} días
                </small>
              </p>
            </div>
            <div class="col col-md-2" data-columna="Zonas">
              <p>
                <span v-for="zona in empresa.zonas" :key="zona">
                  <span v-if="zonas[zona]" class="text-break">
                    {{ zonas[zona].est_zon ? zonas[zona].est_zon  : 'Sin estado'}}, {{ zonas[zona].nom_zon  }}
                    {{ zonas[zona].sec_zon ? zonas[zona].sec_zon : 'Sin sector' }}.
                  </span>
                  <span v-else class="text-muted font-italic">
                    Sin zona especificada
                  </span>
                </span>
              </p>
            </div>
            <div class="col col-md-2" data-columna="Condición de pago">
              <p :class="['text-break', {'text-muted font-italic': listarDatosEmpresa(empresa.cp, 'condicion pago') === 'Sin condición de pago especificada'}]">
                {{ listarDatosEmpresa (empresa.cp, 'condicion pago')}}
              </p>
            </div>
            <div class="col col-md-2" data-columna="Tipo de precio">
              <p :class="['text-break', {'text-muted font-italic': listarDatosEmpresa(empresa.tpre, 'tipos precio') === 'Sin tipo de precio especificado'}]">
                {{ listarDatosEmpresa(empresa.tpre, 'tipos precio') }}
              </p>
            </div>
          </li>
        </router-link>
      </ul>
      <div
        v-else-if="!Object.keys(clientes).length && filtrosActivos"
        class="mensaje-no-items py-5"
      >
        <span class="fa-stack icono position-relative ml-n4 mb-4">
          <font-awesome-icon
            icon="search"
            size="5x"
            class="fa-stack-2x"
          />
          <font-awesome-icon
            icon="times"
            size="2x"
            class="fa-stack-1x"
            style="position: absolute;top: -10px;left: 14px;"
          />
        </span>
        <p class="texto text-center">
          No se encontraron empresas
          <span class="d-block">durante la búsqueda</span>
        </p>
      </div>
      <div
        v-else
        class="mensaje-no-items py-5"
      >
        <font-awesome-icon
          icon="search"
          size="5x"
          class="icono"
        />
        <p class="texto text-center">
          Consulta los clientes a través de
          <span class="d-block">los filtros para continuar</span>
        </p>
      </div>
    </article>

    <AlertaMensaje
      :alerta-mensaje="alertaMensaje"
      @cerrar="alertaMensaje.contenido = ''"
    />

    <transition
      name="custom-classes-transition"
      enter-active-class="animated fadeInRight"
      leave-active-class="animated fadeOutRight"
    >
      <router-view />
    </transition>
  </section>
</template>

<script>
// @ is an alias to /src
import FiltrosTipo1 from '@/components/FiltrosTipo1.vue'
import { apiPost } from '@/funciones/api.js'
import { asignarColorEstado, comprobarRutaHija, diasEstatusFac } from '@/funciones/funciones.js'
import AlertaMensaje from '@/components/AlertaMensaje.vue'
import LoaderKel from '@/components/LoaderKel.vue'

export default {
  name: 'ClientesView',
  components: {
    FiltrosTipo1,
    AlertaMensaje,
    LoaderKel
  },
  data () {
    return {
      asignarColorEstado,
      diasEstatusFac,
      titulo: 'Estados',
      alertaMensaje: { contenido: '' },
      totalSucursales: 0,
      zonas: {},
      zonasFiltro: [],
      tipos: {},
      tiposFiltro: [],
      clientes: {},
      vendedoresFiltro: [],
      vendedorAFiltrar: [],
      tiposAFiltrar: [],
      nombreAFiltrar: '',
      fechaAFiltrar: '',
      estadosAFiltrar: [],
      municipiosAFiltrar: [],
      parroquiasAFiltrar: [],
      estadoSeleccionado: '',
      estatusFacturaAFiltrar: [],
      condiPagoAFiltrar: [],
      tipoPrecioFiltrar: [],
      tipoPrecio: [],
      conDePa: {},
      tiposPrecio: [],
      condicionesPagoSelect: [],
      municipioSeleccionado: '',
      botonDeshabilitado: false,
      modalConfirmacionEliminar: false,
      cargando: false
    }
  },
  methods: {
    // Debe traer las zonas, los vendedores y los tipos de clientes.
    // También los primeros 50 clientes por orden alfabético, los demás los trae al hacer scroll (si
    // no se ha seleccionado otro filtro)
    cargaInicial () {
      this.resultados = {}
      apiPost({ s: 'clientes_generalMv' }, '')
        .then((res) => {
          this.enlistarZonas(res.data.zon)
          this.zonasFiltro = res.data.zon
          this.enlistarTipos(res.data.tcl)
          this.enlistarConDePa(res.data.cp)
          this.enlistarTiPre(res.data.tpre)
          this.tiposFiltro = res.data.tcl
          this.vendedoresFiltro = res.data.ven
          this.condicionesPagoSelect = res.data.cp
          this.tipoPrecio = res.data.tpre
        })
    },
    aplicarFiltros () {
      if (this.timeout) { clearTimeout(this.timeout) }
      this.timeout = setTimeout(() => {
        this.cargando = true
        apiPost({ s: 'clientes_filtradosMv' }, 'tipos=' + this.tiposAFiltrar + '&vendedores=' + this.vendedorAFiltrar + '&nombre=' + this.nombreAFiltrar + '&fecha_desde=' + this.fechaAFiltrar + '&estados=' + this.estadosAFiltrar + '&municipios=' + this.municipiosAFiltrar + '&parroquias=' + this.parroquiasAFiltrar + '&condicion=' + this.condiPagoAFiltrar + '&precio=' + this.tipoPrecioFiltrar + '&factura=' + this.estatusFacturaAFiltrar)
          .then((res) => {
            this.enlistarEmpresas(res.data.emp)
            this.totalSucursales = res.data.emp.length
          })
      }, 500) // delay
    },
    aplicarFiltrosZonas (estadosFil, municipiosFil, parroquiasFil) {
      this.estadosAFiltrar = []
      this.municipiosAFiltrar = []
      this.parroquiasAFiltrar = []

      estadosFil.forEach(e => {
        this.estadosAFiltrar.push(e.est_zon)
      })
      municipiosFil.forEach(m => {
        this.municipiosAFiltrar.push(m.ciu_zon)
      })
      parroquiasFil.forEach(p => {
        this.parroquiasAFiltrar.push(p.sec_zon)
      })

      this.aplicarFiltros()
    },
    eliminarFiltrosSeleccionados () {
      this.vendedorAFiltrar = []
      this.tiposAFiltrar = []
      this.nombreAFiltrar = ''
      this.fechaAFiltrar = ''
      this.estadosAFiltrar = []
      this.municipiosAFiltrar = []
      this.parroquiasAFiltrar = []
      this.estatusFacturaAFiltrar = []
      this.condiPagoAFiltrar = []
      this.tipoPrecioFiltrar = []
      this.clientes = {}
      this.totalSucursales = 0
    },
    abrirVentana (id) {
      window.open(`/clientes/${id}`, '_blank')
    },
    enlistarConDePa (data) {
      const conDePa = {}
      data.forEach(cp => {
        if (!conDePa[cp.id_cp]) {
          conDePa[cp.id_cp] = {
            id_cp: cp.id_cp,
            nom_cp: cp.nom_cp
          }
        }
      })
      this.conDePa = conDePa
    },
    enlistarTiPre (data) {
      const tiposPre = {}
      data.forEach(tpre => {
        if (!tiposPre[tpre.id_tctprel]) {
          tiposPre[tpre.id_tpre] = {
            id_tpre: tpre.id_tpre,
            nom_tpre: tpre.nom_tpre
          }
        }
      })
      this.tiposPrecio = tiposPre
    },
    enlistarEmpresas (data) {
      const empresas = {}
      data.forEach(e => {
        if (!empresas[`${e.nom_emp}-${e.id_emp}`]) {
          empresas[`${e.nom_emp}-${e.id_emp}`] = {
            id_emp: e.id_emp,
            nom_emp: e.nom_emp,
            rif_emp: e.rif_emp,
            id_tcl_suc: e.id_tcl_suc,
            id_zon_suc: e.id_zon_suc,
            est_fac: e.est_fac,
            fec_ven_fac: e.fec_ven_fac,
            fec_pag_fac: e.fec_pag_fac,
            tipos: {},
            zonas: {},
            cp: {},
            tpre: {},
            suc: []
          }
        }
        empresas[`${e.nom_emp}-${e.id_emp}`].tipos[e.id_tcl_suc] = e.id_tcl_suc
        empresas[`${e.nom_emp}-${e.id_emp}`].zonas[e.id_zon_suc] = e.id_zon_suc
        empresas[`${e.nom_emp}-${e.id_emp}`].cp[e.id_cp_ped] = e.id_cp_ped
        empresas[`${e.nom_emp}-${e.id_emp}`].tpre[e.id_tpre_ped] = e.id_tpre_ped
        empresas[`${e.nom_emp}-${e.id_emp}`].suc.push(e.id_suc)
      })
      this.clientes = empresas
      this.cargando = false
    },
    enlistarZonas (data) {
      const zonas = {}
      data.forEach(e => {
        if (!zonas[e.id_zon]) {
          zonas[e.id_zon] = {
            id_zon: e.id_zon,
            est_zon: e.est_zon,
            ciu_zon: e.ciu_zon,
            sec_zon: e.sec_zon
          }
        }
      })
      this.zonas = zonas
    },
    enlistarTipos (data) {
      const tipos = {}
      data.forEach(e => {
        if (!tipos[e.id_tcl]) {
          tipos[e.id_tcl] = {
            id_tcl: e.id_tcl,
            nom_tcl: e.nom_tcl
          }
        }
      })
      this.tipos = tipos
    },
    listarDatosEmpresa (array, tipo) {
      const valores = Object.keys(array).filter((item) => { return item !== 'null' })
      let mensaje = ''

      switch (tipo) {
        case 'tipos empresa':
          if (valores.length) {
            mensaje = valores.map((tipo) => {
              const tipoName = this.tipos[tipo].nom_tcl.toLowerCase()
              return tipoName.charAt(0).toUpperCase() + tipoName.slice(1)
            }).join(', ')
          } else mensaje = 'Sin tipo especificado'
          break
        case 'condicion pago':
          if (valores.length) {
            mensaje = valores.map((condicion) => {
              return this.conDePa[condicion] ? this.conDePa[condicion].nom_cp : 'Sin condición de pago especificada'
            }).join(', ')
          } else mensaje = 'Sin condición de pago especificada'
          break
        case 'tipos precio':
          if (valores.length) {
            mensaje = valores.map((precio) => {
              return this.tiposPrecio[precio].nom_tpre
            }).join(', ')
          } else mensaje = 'Sin tipo de precio especificado'
          break
      }

      return mensaje
    }
  },
  computed: {
    filtrosActivos () {
      return (this.estadosAFiltrar.length || this.municipiosAFiltrar.length || this.parroquiasAFiltrar.length ||
        this.tiposAFiltrar.length || this.vendedorAFiltrar.length || this.nombreAFiltrar.length || this.fechaAFiltrar.length)
    },
    siMuestraRutaHija () {
      return comprobarRutaHija(this.$route.path.slice(1).split('/'))
    },
    vendedoresDisponiblesParaAsignar () {
      return this.vendedoresFiltro.filter(vendedor => vendedor.id_per !== this.vendedorAct) || this.vendedoresFiltro
    }
  },
  mounted () {
    this.cargaInicial()
  }
}
</script>
<style lang="scss" scoped>
.botones-opcionales {
  right: 1rem;

  @media screen and (min-width: 768px) {
    right: calc(50% - 109px);
  }
  @media screen and (min-width: 992px) {
    right: calc(50% - 205px);
  }
}

.table.t-clientes {
  margin: 2.5rem auto 6rem;

  .contenido > [class*=col] {line-height: 1.3}

  // Define los breakpoints para el responsive de ul.table
  $breakpointToChange: "768px";
  @include ul-table-responsive-at ($breakpointToChange);

  // Anchos específicos de columnas
  @media screen and (min-width: $breakpointToChange) {
    .acciones {width: 140px;}
  }
}
</style>
